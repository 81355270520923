<template>
  <v-dialog width="35%" v-model="dialog" @click:outside="$emit('close')">
    <v-card>
      <v-card-title> Delete {{ groupInfo.groupName }} </v-card-title>
      <v-card-text>
        Are you sure you want to delete {{ groupInfo.groupName }}?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('close')">Cancel</v-btn>
        <v-btn
          :loading="deleting"
          @click="deletePromoItemGroup">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayAlert } from '@/mixins/alert'
import PromoItemGroup from "@/axios/promotion-item-group-endpoint"
export default {
  name: "deletePromoItemGroup",
  props: {
    groupInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      deleting: false
    }
  },
  mixins: [displayAlert],
  mounted () {
    this.dialog = true
  },
  methods: {
    async deletePromoItemGroup() {
      this.deleting = true
      try {
        await PromoItemGroup.deletePromoItemGroup(this.groupInfo.groupId)
        this.$emit("update")
        this.$emit("close")
      } catch (err) {
        this.handleError(err)
      } finally {
        this.deleting = false
      }
    }
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "35%" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(" Delete " + _vm._s(_vm.groupInfo.groupName) + " ")
          ]),
          _c("v-card-text", [
            _vm._v(
              " Are you sure you want to delete " +
                _vm._s(_vm.groupInfo.groupName) +
                "? "
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.deleting },
                  on: { click: _vm.deletePromoItemGroup }
                },
                [_vm._v(" Submit ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }